<template>
  <div class="home">
    <div className="page flex-col">
      <div className="bd1 flex-col">
        <Header ref="header" />
        <div class="container">
          <div class="width-left flex-col">
            <VideoList ref="videolist" />
            <EntryExitRecords />
          </div>
          <div class="width-right flex-col">
            <ChargeBox @countPayAmountByDuty="countPayAmountByDuty" />
            <EntryAndExitPicture />
          </div>
        </div>
        <!-- <div className="layer3 flex-row">
        </div> -->
        <div class="layer6 flex-row" v-if="false">
          <div class="box11 flex-row">
            <EntryExitRecords />
          </div>
          <EntryAndExitPicture />
        </div>
      </div>
    </div>
    <ParkingLotList />
    <!-- 停车场列表选择开关 -->
    <!-- <div class="selectParkingLot" @click="setParkSwitch">选择停车场</div> -->
     <el-image
      ref="preview"
      class="hideImgDiv"
      :src="require('../assets/image/BG@2x.png')"
      :preview-src-list="[src || require('../assets/image/BG@2x.png')]"
      :z-index="90000"
    ></el-image>
  </div>
</template>

<script>
import Header from "../components/Header";
import ParkingLotList from "../components/ParkingLotList";
import VideoList from "../components/VideoList";
import ChargeBox from "../components/ChargeBox";
import EntryExitRecords from "../components/EntryExitRecords";
import EntryAndExitPicture from "../components/EntryAndExitPicture";
import { InitWebSocket } from "@/utils/websocket";
import { mapState } from "vuex";
import store from "../store";
import CompanyArr from "../config";
import {updateLogAndOrder, addCarparkUseLogAdmin} from "@/network/api";
// import { h } from "vue";
export default {
  name: "Home",
  components: {
    Header,
    ParkingLotList,
    VideoList,
    ChargeBox,
    EntryExitRecords,
    EntryAndExitPicture,
  },
  data() {
    return {
      src: []
    }
  },
  computed: {
    ...mapState(["parkSwitch", "errCar"]),
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      InitWebSocket();
    }
  },
  watch: {
    errCar: {
      deep: true,
      handler: function (val) {
        if (val.length > 0) {
          this.errNotify(val[0]);
        }
      },
    },
  },
  mounted() {
    setInterval(() => {
      this.$refs.header.fastLogin();
    }, CompanyArr.time * 60 * 1000);
  },
  methods: {
    errNotify(data) {
      console.log(data);
      let tip =
        data.data.cfCarParkCarType.name == "黑名单"
          ? data.data.cfCarParkCarType.name
          : `${data.data.cfCarParkCarType.name}(已过期)`;
      let notify = this.$notify({
        title: "提示",
        message: (
          <div class="notiBox">
            <p style="font-size: 20px">车牌号: {data.numberPlate}</p>
            <div class="section77">
              <span class="word188">{tip}</span>
            </div>
            <p>入场信息: {data.cfCarParkCheckpointIn.name || ""}</p>
            <p>出场信息: {data.cfCarParkCheckpointOut.name || ""}</p>
            {data.data.cfCarParkCarType.name == "黑名单" && (<p>备注信息：{data.data.cfCarParkCarType.description}</p>)}
            {data.data.cfCarParkCarType.name == "黑名单" && (<p>进出场图片:</p>)}
            {data.data.cfCarParkCarType.name == "黑名单" && (<img class="notiImg" src={data.inSmallImage} onClick={() => {this.showImg(data.inBigImage)}} />)}
            <div class="section10 flex-col" onClick={() => {this.openGate(data, notify)}}>
              <span class="info12">开闸</span>
            </div>
          </div>
        ),
        position: "top-left",
        type: "warning",
        duration: data.data.cfCarParkCarType.name == '黑名单' ? 0 : 10000,
        customClass: 'notify-warning',
      });
    },
    setParkSwitch() {
      store.commit("SETPARKLISTSWITCH", !this.parkSwitch);
    },
    countPayAmountByDuty() {
      this.$refs.header.countPayAmountByDuty();
    },
    showImg(data) {
      this.src = data
      this.$refs.preview.clickHandler();
    },
    async openGate(data, notify) {
      let tip = "release_";
      let item = {
        checkpointId: 0,
      };
      if (data.outTime > 0) {
        // 开闸开 出口
        var {message} = await updateLogAndOrder({
          carParkUseLogId: data.oddata.cfOrder.goodsId,
          payTime: new Date().getMilliseconds(),
          orderId: data.oddata.cfOrder.id,
          amountOfMoney: 0,
          actionType: "abnormal_termination_of_order",
        });
        this.$message({
          message,
          type: "success",
        });
      } else {
        item.checkpointId = data.cfCarParkCheckpointIn.id;
        const {msg} = await addCarparkUseLogAdmin({
          numberPlate: data.numberPlate,
          carParkId: data.carParkId,
          inTime: data.inTime,
          inReleaseType: 2,
          inCheckPointId: data.inCheckPointId,
          createPosition: 'out',
          createTime: data.createTime,
          inBigImage: data.inBigImage.substring(data.inBigImage.indexOf('picture')),
          inSmallImage: data.inSmallImage.substring(data.inSmallImage.indexOf('picture')),
          carType: data.carType,
          carNumberPlateColor: data.carNumberPlateColor
        })
        console.log(msg)
        this.$refs.videolist.onVideoIndex(item, tip);

      }
      notify.close();
    },
  },
};
</script>

<style scoped>
.selectParkingLot {
  position: absolute;
  width: 30px;
  background: red;
  left: 0;
  top: 110px;
  z-index: 2;
  color: #fff;
  text-align: center;
}
.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}
.width-left {
  width: 74%;
}
.width-right {
  width: 25%;
}
.hideImgDiv {
  position: absolute;
}
.hideImgDiv /deep/ .el-image__inner {
  display: none;
}
</style>
