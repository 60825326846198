<template>
  <div className="layer1 flex-col">
    <div className="box1 flex-col">
      <div className="section1 flex-row">
        <div className="flex-row">
          <div class="flex-row" style="cursor: pointer;" @click="editPark">
            <img
            v-if="company.name != 'dewei' && company.name != 'sixiang'"
            className="icon1"
            src="../../assets/logo.png"
          />
          <img
            v-if="company.name == 'sixiang'"
            className="icon1"
            style="margin-top: 2px; width: 28px; height: 34px"
            src="../../assets/logo.png"
            alt=""
          />
          <span className="word1">{{ company.title }}</span>
          </div>
          <el-select
            class="selectParkingLot"
            v-model="carParkIndex"
            @change="onList"
            placeholder="请选择停车场"
          >
            <el-option
              v-for="item in carParkList"
              :key="item.index"
              :label="item.name"
              :value="item.index"
            />
          </el-select>
          <span className="info1">{{ nowTime }}</span>
          <el-input
            style="width: 200px; margin-left: 50px"
            readonly
            placeholder="输入车牌号查询车辆"
            prefix-icon="el-icon-search"
            @click="showCarBox"
          />
          <el-button class="editBtn" style="margin-left: 15px" @click="editPark">
            编辑车位
          </el-button>
        </div>
        <div className="flex-row">
          <span className="word2-2" style="margin-right: 18px">
            今日现金收费：{{ totalCashCharge }}
          </span>
          <span className="word2" style="margin-right: 18px">{{ user.userName }}</span>
          <div :class="['live-box', isLive ? 'success-box' : 'error-box']">
            <div :class="['live-tip', isLive ? 'success-tip' : 'error-tip']"></div>
            {{ isLive ? "在线" : "离线" }}
          </div>
          <!-- <el-button icon="el-icon-full-screen" @click="setWindow" circle></el-button> -->
          <!-- <el-button
            type="defalut"
            icon="el-icon-refresh"
            @click="fastLogin"
          >刷新</el-button> -->
          <div class="icon-btn" @click="setWindow">
            <i class="el-icon-full-screen"></i>全屏
          </div>
          <div class="icon-btn" @click="fastLogin">
            <i class="el-icon-refresh"></i>刷新
          </div>
          <!-- <el-button className="main3 flex-col" @click="drawer = true">
            <span className="word3">全局配置</span>
          </el-button> -->
          <el-button className="main1 flex-col" @click="onParkingRecord">
            <span className="word3">停车记录</span>
          </el-button>
          <div className="main2 flex-col" @click="onLogin">
            <span className="info2">点我交班</span>
          </div>
        </div>
      </div>
      <div className="section2 flex-col" />
    </div>

    <HandOver ref="handover" />
    <ParkingRecord ref="ParkingRecord" />
  </div>
  <CarDialog ref="cardialog" />
  <el-drawer v-model="drawer" title="全局配置文件">
    <div style="display: flex">
      <div>进出场车辆提示：</div>
      <el-switch
        v-model="globalConfig.promptEntryExitCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex; margin-top: 10px">
      <div>已支付车辆提示：</div>
      <el-switch
        v-model="globalConfig.promptPayCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex; margin-top: 10px">
      <div>出场记录只显示收费车辆：</div>
      <el-switch
        v-model="globalConfig.promptChargeOutCar"
        active-text="开启"
        inactive-text="关闭"
      />
    </div>
    <div style="display: flex; margin-top: 10px">
      <div>进出场图片显示：</div>
      <el-switch
        v-model="globalConfig.inOutImageType"
        active-text="小图"
        inactive-text="大图"
      />
    </div>
  </el-drawer>
  <EditPark ref="editParkRef" @fetch-data="getCarParkList" />
</template>

<script>
import EditPark from "../EditPark";
import HandOver from "./HandOver.vue";
import ParkingRecord from "../ParkingRecord";
import { mapState } from "vuex";
import dayjs from "dayjs";
import store from "../../store";
import {
  countPayAmountByDuty,
  getCarParkList,
  getCheckPointByQuery,
} from "@/network/api";
import CompanyArr from "../../config";
import CarDialog from "../CarDialog";
export default {
  components: { HandOver, ParkingRecord, CarDialog, EditPark },
  computed: {
    ...mapState(["globalConfig", "parkSwitch", "isLive", "carParkIndex"]),
  },
  data() {
    return {
      nowTime: "",
      user: JSON.parse(localStorage.getItem("userInfo")),
      drawer: false,
      totalCashCharge: 0,
      company: CompanyArr,
      fullFlag: true,
      queryFrom: {
        page: 1,
        size: 50,
      },
      carParkList: [],
      value: "",
    };
  },
  created() {
    this.setTime();
    this.countPayAmountByDuty();
    this.getCarParkList();
  },
  watch: {
    globalConfig: {
      deep: true,
      handler(value) {
        store.commit("SETCONFIG", value);
      },
    },
    carParkList: function () {
      this.getCheckPointByQuery();
    },
  },
  methods: {
    editPark() {
      this.$refs.editParkRef.showEdit(this.carParkList[this.carParkIndex]);
    },
    showCarBox() {
      this.$refs.cardialog.showMod();
    },
    onList(index) {
      this.$store.commit("SetCarParkIndex", index);
      this.getCheckPointByQuery();
      // this.$store.commit("SETPARKLISTSWITCH", !this.parkSwitch);
    },
    async getCheckPointByQuery() {
      var { data, code } = await getCheckPointByQuery({
        page: 1,
        size: 100,
        carParkId: this.carParkList[this.carParkIndex].id,
      });
      if (code != 10002) return;
      this.$store.commit("SETCHECKPOINTLIST", data);
    },
    async getCarParkList() {
      var { data, code } = await getCarParkList(this.queryFrom);
      if (code == 10002) {
        data.map((v, key) => {
          v.index = key;
        });
        this.carParkList = data;
        this.$store.commit("SETCARPARKLIST", data);
      }
    },
    setParkSwitch() {
      store.commit("SETPARKLISTSWITCH", !this.parkSwitch);
    },
    setWindow() {
      if (this.fullFlag) {
        this.setFullWindow();
      } else {
        this.notFullWindow();
      }
    },
    setFullWindow() {
      if (document.documentElement.RequestFullScreen) {
        document.documentElement.RequestFullScreen();
      }
      //兼容火狐
      console.log(document.documentElement.mozRequestFullScreen);
      if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      }
      //兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
      if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      }
      //兼容IE,只能写msRequestFullscreen
      if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
      this.fullFlag = false;
    },
    notFullWindow() {
      if (document.exitFullScreen) {
        document.exitFullscreen();
      }
      //兼容火狐
      console.log(document.mozExitFullScreen);
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      //兼容谷歌等
      if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      //兼容IE
      if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.fullFlag = true;
    },
    /**
     * 统计现金收款金额
     */
    async countPayAmountByDuty() {
      const { data, code } = await countPayAmountByDuty({
        paymentAgencyShortName: "cash_pay_cny",
      });
      if (code === 10002) {
        this.totalCashCharge = data == null ? 0 : data;
      }
    },
    onLogin() {
      this.$refs.handover.showDialog();
    },
    fastLogin() {
      this.$refs.handover.fastLogin();
    },
    setTime() {
      setInterval(() => {
        this.nowTime = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      }, 1000);
    },
    onParkingRecord() {
      this.$refs.ParkingRecord.showDialog();
    },
  },
};
</script>

<style scoped>
.selectParkingLot {
  height: 36px;
  border-radius: 4px;
  border-color: #2a3f74;
  font-size: 14px;
  color: #f3f3f3;
  line-height: 36px;
  margin: 2px 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-btn {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
  margin-left: 15px;
  cursor: pointer;
}
.icon-btn i {
  padding-right: 6px;
}
.live-box {
  display: flex;
  font-size: 14px;
  color: #ffffff;
  line-height: 40px;
  align-items: center;
  margin-right: 10px;
}
.live-tip {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
.success-tip {
  background: #11aa4e;
}
.error-tip {
  background: #eb514a;
}
.success-box {
  color: #11aa4e;
}
.error-box {
  color: #eb514a;
}
.el-button:focus, .el-button:hover {
  border: 1px solid #2a3f74 !important;
  color: #bed0ff !important;
  background: #0c1e4b !important;
}
</style>
