<template>
  <router-view />
</template>

<script>
import Company from "./config";
export default {
  created() {
    console.log(Company);
    document.title = Company.title;
  },
};
</script>
<style>
@import url("./assets/style/public.css");

/* Reset */
* {
  margin: 0;
  padding: 0;
}
div {
  /* transition: all 0.3s; */
}
em,
i {
  font-style: normal;
}
li {
  list-style: none;
}
img {
  border: 0;
  vertical-align: middle;
}
button {
  cursor: pointer;
}
a {
  color: #666;
  text-decoration: none;
}
a:hover {
  color: #c81623;
}
/* Reset End */

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma, Arial,
    PingFang SC-Light, Microsoft YaHei, serif;
}
button {
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  outline: none;
  background-color: transparent;
}

button:active {
  opacity: 0.6;
}

.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.el-notification {
  background: #021831;
  box-shadow: 0px 6px 24px 8px rgba(23, 88, 255, 0.24);
  border-radius: 4px;
  border: 1px solid rgba(23, 88, 255, 0.6);
}

.el-notification .el-icon-warning,
.el-message-box__status.el-icon-warning {
  color: #17b4ff;
}

.el-notification__title,
.el-dialog__title {
  font-size: 16px;
  color: #17b4ff;
}
.el-notification__content {
  color: #bed0ff;
}

.el-popper.is-light,
.el-message-box,
.el-dialog {
  background: #021831;
  box-shadow: 0px 6px 24px 8px rgba(23, 88, 255, 0.24);
  border-radius: 4px;
  border: 1px solid rgba(23, 88, 255, 0.6);
}
.el-popper.is-light p,
.el-message-box__status + .el-message-box__message,
.el-form-item__label {
  color: #bed0ff;
}
.el-message-box__title {
  color: #17b4ff;
}
.el-popper.is-light .el-popper__arrow::before {
  background: #021831;
  border: 1px solid rgba(23, 88, 255, 0.6);
}
.el-input__inner {
  background: #0c1e4b;
  border-radius: 4px;
  border: 1px solid #2a3f74;
  color: #bed0ff;
}
.el-input__inner:hover {
  border: 1px solid #2a3f74;
}
.el-button--default {
  border-radius: 4px;
  border: 1px solid #2a3f74;
  color: #bed0ff;
  background: #0c1e4b;
}
.el-button--default:hover {
  border: 1px solid #2a3f74;
  background: #0c1e4b;
}
.el-button--primary {
  background: #1758ff;
  border-radius: 4px;
  color: #bed0ff;
  border-color: #1758ff;
}
.el-pagination .btn-next,
.el-pagination .btn-prev {
  background: #0c1e4b !important;
  border-radius: 4px;
  border: 1px solid #2a3f74;
}
.el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  word-break: break-word;
  background: #031134;
  color: #17b4ffff;
  height: 40px;
  font-size: 13px;
  border-color: #3f558f;
}
.el-table tr,
.el-table__header {
  border-color: #3f558f;
}
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #222c4e;
}
.el-table__empty-block {
  background: #021831;
}
.el-table {
  --el-table-border-color: #3f558f;
  --el-table-border: 1px solid #3f558f;
  --el-table-font-color: #bed0ff;
  --el-table-tr-background-color: #021831;
  --el-table-row-hover-background-color: #021831;
}
.el-scrollbar {
  /* background: #222c4e; */
  border-color: #222c4e;
  --el-scrollbar-background-color: #222c4e;
  --el-scrollbar-hover-background-color: #17b4ff1f;
  color: #bed0ff;
}
.el-select__popper.el-popper[role="tooltip"] {
  background: #222c4e;
  border: 1px solid #222c4e;
}
.el-select__popper.el-popper[role="tooltip"][data-popper-placement^="bottom"]
  .el-popper__arrow::before {
  border: 1px solid #222c4e;
}
.el-select-dropdown__item {
  color: #bed0ff;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #17b4ff1f;
}
.el-picker-panel,
.el-picker-panel__footer,
.el-date-table th,
.el-date-picker__time-header,
.el-date-picker__header--bordered {
  background: #222c4e;
  border-color: rgba(63, 85, 143, 0.6);
  color: #bed0ff;
}
.el-picker-panel .el-time-panel,
.el-time-panel__footer {
  background: #222c4e;
  border-color: rgba(63, 85, 143, 0.6);
  color: #bed0ff;
}
.el-time-panel__btn,
.el-date-picker__header-label,
.el-month-table td .cell,
.el-year-table td .cell {
  color: #bed0ff;
}
.el-time-spinner__item.active:not(.disabled) {
  color: #17b4ff;
}
.el-time-panel__content::after, .el-time-panel__content::before {
  border-top: 1px solid rgba(63, 85, 143, 0.6);
  border-bottom: 1px solid rgba(63, 85, 143, 0.6);
}
.el-pagination.is-background .el-pager li {
  background: #0c1e4b;
  border-radius: 2px;
  border: 1px solid rgba(63, 85, 143, 0.6);
  color: #bed0ff;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #0c1e4b;
  border-radius: 2px;
  border: 1px solid #17b4ff;
  color: #17b4ff;
}
.el-dialog__footer {
  border-top: 1px solid #2a3f74;
  padding-top: 20px;
}
.el-radio__inner,
.el-radio__input.is-checked .el-radio__inner::after {
  background-color: #222c4e;
}

.carnum-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.carnum-box-item {
  width: 36px;
  height: 36px;
  background: #0c1e4b;
  border-radius: 4px;
  border: 1px solid #2a3f74;
  font-size: 18px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  margin: 3px 6px;
  cursor: pointer;
}
.carnum-active-item {
  background: #1758ff;
  border: 1px solid #2a3f74;
}
.el-popper.is-light .el-popper__arrow:before {
  background: #222c4e;
}
</style>
