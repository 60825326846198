<template>
  <div class="tab-nav">
    <el-dialog v-model="dialogVisible" title="车牌号查询" width="388px">
      <div class="container-box">
        <el-form ref="formRef" :model="form" :rules="rules">
          <!-- <el-form-item label="车牌号" prop="cartype">
            <el-radio-group v-model="form.cartype">
              <el-radio label="0">普通车牌</el-radio>
              <el-radio label="1">新能源车牌</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item prop="carNum">
            <div class="carNum">
              <el-popover placement="bottom" :width="364" v-model:visible="visibleShow">
                <div class="carnum-title">
                  <div>车牌属地选择</div>
                  <i class="el-icon-close" @click="visibleShow = false"></i>
                </div>
                <div class="carnum-box">
                  <div
                    :class="[
                      'carnum-box-item',
                      i == licenseplate[0] && 'carnum-active-item',
                    ]"
                    v-for="(i, key) in carName"
                    :key="key"
                    @click="setCarNum(i)"
                  >
                    {{ i }}
                  </div>
                </div>
                <template #reference>
                  <div class="carnum-item">
                    {{ licenseplate[0] }}<i class="el-icon-caret-bottom"></i>
                  </div>
                </template>
              </el-popover>
              <template v-for="i in 7" :key="i">
                <el-input
                  v-model="licenseplate[i]"
                  maxlength="1"
                  class="carnum-item1"
                  ref="ipt"
                  @input="changeInput(i)"
                  @keydown.delete="test($event, i)"
                  @focus="focusTest($event)"
                  v-if="i != 7"
                />
                <div class="carnum-item11" v-if="i == 7 && form.cartype == 0">新</div>
                <el-input
                  v-model="licenseplate[i]"
                  maxlength="1"
                  class="carnum-item1"
                  ref="ipt"
                  @keydown.delete="test($event, i)"
                  @focus="focusTest($event)"
                  @input="changeInput(i)"
                  v-if="i == 7 && form.cartype == 1"
                  placeholder="新"
                />
              </template>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="btn-contaier">
              <el-button class="btn-box2" @click="cleanCar"> 清空车牌 </el-button>
              <el-button class="btn-box2-q" @click="searchCar"> 查询 </el-button>
            </div>
          </el-form-item>
          <div class="infoMsg" v-if="msg.pay">
            <div class="msg-item">车牌号：{{ msg.car }}</div>
            <div class="msg-item">进场时间：{{ msg.enter_time }}</div>
            <div class="msg-item">停车时长：{{ msg.time }}</div>
            <div class="msg-item">需要支付：{{ msg.pay }}</div>
            <div class="msg-img" v-if="msg.inBigImage">
              <span>进场图片</span>
              <el-image
                style="width: 298px; height: 160px"
                :src="msg.inBigImage"
                :preview-src-list="[msg.inBigImage]"
                fit="fit"
              />
            </div>
          </div>
          <div class="infoMsg" v-else>
            <div class="msg-item" v-if="msg.car">车牌号：{{ msg.car }}</div>
            <div class="msg-item">{{ msg.err }}</div>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, defineExpose } from "vue";
import { getCarParkUseLogList, queryOrderByUseId } from "@/network/api/index";
import { $timeFormat, timeFn } from "@/utils/index";
import { isVehicleNumber } from "@/utils/validate";
import { nextTick } from "vue";
const msg = reactive({
  car: "",
  pay: "",
  err: "暂未获取到停车记录信息",
  enter_time: "",
  time: "",
  inBigImage: "",
});
const form = reactive({
  cartype: "1", //车子类型
  carNum: "",
});
const getCarFun = (value) => {
  getCarParkUseLogList({
    page: 1,
    size: 1000,
    numberPlate: value,
  }).then((res) => {
    msg.car = "";
    msg.pay = "";
    msg.err = `暂未获取到 ${value} 停车记录信息`;
    msg.enter_time = "";
    msg.time = "";
    msg.inBigImage = "";
    if (res?.data && !res.data[0].payTime) {
      queryOrderByUseId(res.data[0].id).then((res) => {
        const data = res.data;
        msg.enter_time = data.cfCarParkUseLog.inTime
          ? $timeFormat(data.cfCarParkUseLog.inTime, "YYYY-MM-DD HH:mm:ss")
          : "-";
        msg.car = value;
        const outTime = data.cfCarParkUseLog.outTime
          ? data.cfCarParkUseLog.outTime
          : new Date();
        msg.time = timeFn(data.cfCarParkUseLog.inTime, outTime);
        msg.pay = data.cfOrder.amountsPayable + "元";
        msg.inBigImage = data.cfCarParkUseLog.inBigImage;
      });
    }
  });
};
const checkCarNum = (rule, value, callback) => {
  initData();
  if (value != "" && !isVehicleNumber(value)) {
    callback(new Error("请输入正确车牌号"));
  } else {
    if (value != "") {
      getCarFun(value);
      callback();
    } else {
      callback("请输入正确车牌号");
    }
  }
};
const rules = reactive({
  cartype: [{ required: true, message: "请选择", trigger: "blur" }],
  carNum: [{ required: true, validator: checkCarNum, trigger: "change" }],
});
const licenseplate = reactive(["闽", "", "", "", "", "", "", ""]);
const visibleShow = ref(false);
const ipt = ref([]);
const formRef = ref();
const dialogVisible = ref(false);
onMounted(async () => {});
const searchCar = async () => {
  verifyCarNum();
  await formRef.value.validateField(["carNum"], async (valid) => {
    console.log(valid);
  });
};
const cleanCar = () => {
  form.carNum = "";
  licenseplate[0] = "闽";
  licenseplate[1] = licenseplate[2] = licenseplate[3] = licenseplate[4] = licenseplate[5] = licenseplate[6] = licenseplate[7] =
    "";
  setTimeout(() => {
    formRef.value.resetFields();
  }, 10);
};
// 省份选择
const setCarNum = (i) => {
  licenseplate[0] = i;
  visibleShow.value = false;
  verifyCarNum();
};
// 车牌选择
const changeInput = (i) => {
  const val = licenseplate[i];
  const reg = /^[A-Za-z0-9]+$/;
  if (!reg.test(val)) {
    licenseplate[i] = "";
    form.carNum = "";
  } else {
    licenseplate[i] = val.toUpperCase();
    if ((form.cartype == 0 && i < 6) || (form.cartype == 1 && i < 7)) {
      ipt.value[i].$el.children[0].focus();
    }
    verifyCarNum();
  }
};
const verifyCarNum = () => {
  console.log("验证车牌");
  if (
    licenseplate[1] &&
    licenseplate[2] &&
    licenseplate[3] &&
    licenseplate[4] &&
    licenseplate[5] &&
    licenseplate[6]
  ) {
    const str = licenseplate.join("");
    console.log(str);
    form.carNum = str;
  } else {
    form.carNum = "";
  }
};
const test = (event, i) => {
  licenseplate[i] = "";
  if (i != 1) {
    setTimeout(() => {
      ipt.value[i - 2].$el.children[0].focus();
      searchCar();
    }, 10);
  }
};
const focusTest = (event, pos = 0) => {
  nextTick(() => {
    let ctrl = event.target;
    if (ctrl.setSelectionRange) {
      ctrl.focus();
      ctrl.setSelectionRange(pos, pos);
    }
  });
};
const showMod = () => {
  dialogVisible.value = !dialogVisible.value;
  if (dialogVisible.value) {
    cleanCar();
    initData();
  }
};
const initData = () => {
  msg.car = "";
  msg.pay = "";
  msg.err = `暂未获取到停车记录信息`;
  msg.enter_time = "";
  msg.time = "";
  msg.inBigImage = "";
};
// 暴露属性
defineExpose({
  showMod,
});
// 车牌引用
const carName = reactive([
  "贵",
  "粤",
  "京",
  "冀",
  "沪",
  "津",
  "晋",
  "蒙",
  "辽",
  "吉",
  "黑",
  "苏",
  "浙",
  "皖",
  "闽",
  "赣",
  "鲁",
  "豫",
  "鄂",
  "湘",
  "桂",
  "琼",
  "渝",
  "川",
  "云",
  "藏",
  "陕",
  "甘",
  "青",
  "宁",
  "新",
]);
</script>

<style lang="scss" scoped>
.tab-nav {
  width: 364px;
  background: #021831;
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  .container-box {
    padding: 12px;
    display: flex;
    flex-direction: column;
    .box-head {
      width: 100%;
      height: 160px;
      background: #1758ff;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      .head-tip {
        width: 36px;
        height: 36px;
      }
      .head-txt {
        font-size: 14px;
        color: #ffffff;
        margin: 16px 0px;
      }
      .head-num {
        font-size: 28px;
        font-weight: normal;
        color: #2ff0fc;
      }
    }
    .carNum {
      display: flex;
      align-content: center;
      justify-content: space-between;
      .carnum-item {
        width: 36px;
        height: 36px;
        background: #1758ff;
        border-radius: 4px;
        border: 1px solid #2a3f74;
        font-size: 18px;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i {
          font-size: 12px;
        }
      }
      div.carnum-item11 {
        font-size: 12px;
        width: 36px;
        height: 36px;
        background: #0c1e4b;
        border-radius: 4px;
        border: 1px solid #2a3f74;
        color: #bed0ff;
        text-align: center;
        line-height: 36px;
      }
      .carnum-item1 {
        width: 36px;
        height: 36px;
        font-size: 18px;
        font-weight: 500;
        color: #17b4ff;
        line-height: 36px;
        text-align: center;
        ::v-deep .el-input__inner {
          height: 36px;
          text-align: center;
          padding: 0px;
        }
      }
    }

    .infoMsg {
      background: #222c4e;
      border-radius: 4px;
      padding: 16px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #bed0ff;
      line-height: 18px;
      margin-bottom: 18px;
    }

    .btn-box {
      width: 340px;
      height: 40px;
      background: #1758ff;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      line-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px 0px;
      cursor: pointer;
      &:hover,
      &:focus {
        border-color: #1758ff;
      }
      ::v-deep span {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn-tip {
        width: 20px;
        height: 19px;
        margin-right: 10px;
        margin-bottom: 1px;
      }
    }
    .qrcode {
      background: #11aa4e;
      color: #ffffff;
      &:hover,
      &:focus {
        border-color: #11aa4e;
      }
    }
  }
}
::v-deep .el-input__inner {
  height: 36px;
}
.msg-img {
  margin-top: 10px;
  position: relative;
  span {
    width: 72px;
    height: 24px;
    background: #1758ff;
    border-radius: 0px 0px 4px 0px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
  }
}

.btn-contaier {
  display: flex;
  align-items: center;
  .btn-box2 {
    width: 48%;
    &:hover,
    &:focus {
      background: #0c1e4b;
      border: 1px solid #2a3f74;
    }
  }
  .btn-box2-q {
    width: 48%;
    background: #1758ff;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &:hover,
    &:focus {
      border-color: #1758ff;
    }
  }
}
</style>

<style lang="scss">
.spc-item-ipt input::-webkit-input-placeholder {
  /* WebKit browsers*/
  font-size: 14px;
  color: #bed0ff;
}
.spc-item-ipt input::-moz-input-placeholder {
  /* Mozilla Firefox 4 to 18*/
  font-size: 14px;
  color: #bed0ff;
}
.spc-item-ipt input::-moz-input-placeholder {
  /* Mozilla Firefox 19+*/
  font-size: 14px;
  color: #bed0ff;
}

.spc-item-ipt input::-ms-input-placeholder {
  /* Internet Explorer 10+*/
  font-size: 14px;
  color: #bed0ff;
}
</style>