<template>
  <div class="box8 flex-row">
    <div class="mod6 flex-col">
      <div class="box9 flex-col">
        <span class="word95">入场大图</span>
        <div class="box10 flex-col" />
        <el-image
          fit="scale-down"
          class="pic2"
          v-if="inShow"
          :preview-src-list="[
            globalConfig.inOutImageType
              ? carImage.inSmallImage
              : carImage.inImage || require('../../assets/image/BG@2x.png'),
          ]"
          :z-index="9999999"
          :src="
            globalConfig.inOutImageType
              ? carImage.inSmallImage
              : carImage.inImage || require('../../assets/image/BG@2x.png')
          "
          @error="imgErrorIn"
        />
      </div>
    </div>
    <div class="mod7 flex-col">
      <div class="layer23 flex-col">
        <span class="info40">出场大图</span>
        <div class="mod8 flex-col" />
        <el-image
          fit="scale-down"
          class="img1"
          v-if="outShow"
          :preview-src-list="[
            globalConfig.inOutImageType
              ? carImage.outSmallImage
              : carImage.outImage || require('../../assets/image/BG@2x.png'),
          ]"
          :z-index="9999999"
          :src="
            globalConfig.inOutImageType
              ? carImage.outSmallImage
              : carImage.outImage || require('../../assets/image/BG@2x.png')
          "
          @error="imgErrorOut"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let inTime = "";
let outTime = "";
export default {
  computed: {
    ...mapState(["carImage", "globalConfig"]),
  },
  methods: {
    imgErrorIn() {
      // console.log("图片请求失败");
      // console.log("图片请求失败:", this.carImage);
      // console.log(this.carImage.inImage, this.carImage.inSmallImage, "store  in");
      this.inShow = false;
      inTime = setTimeout(() => {
        this.inShow = true;
      }, 1000);
    },
    imgErrorOut() {
      // console.log("图片请求失败");
      // console.log("图片请求失败:", this.carImage);
      // console.log(this.carImage.outImage, this.carImage.outSmallImage, "store  out");
      this.outShow = false;
      outTime = setTimeout(() => {
        this.outShow = true;
      }, 1000);
    },
  },
  watch: {
    carImage: {
      deep: true,
      handler: function (value) {
        console.log("value发生了变化", value);
        if (inTime) {
          this.inShow = true;
          clearTimeout(inTime);
        }
        if (outTime) {
          this.outShow = true;
          clearTimeout(outTime);
        }
      },
    },
  },
  data() {
    return {
      url:
        "http://cdn-aliyun.tutuhy.com/picture/0/dcababe7-bb8c7b2e/20220621/16/20220621_162222_738_plate.jpg",
      inShow: true,
      outShow: true,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-image__placeholder) {
  background: #021831;
}
</style>
