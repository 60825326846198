<template>
  <div className="group15 flex-col" style="width: 100%">
    <div className="bd6 flex-row">
      <div className="box3 flex-row">
        <div
          :class="[deviceList.length != 4 ? 'group16-0' : 'group16-3', 'flex-col']"
          v-for="(item, index) in deviceList"
          :key="item.id"
        >
          <div className="layer5-0 flex-col">
            <span className="word11-0">{{ item.title }}</span>
            <el-image
              :id="'show_video' + (index + 1)"
              className="pic1-0"
              :src="require('../../assets/no.png')"
              v-if="item.brand == 'zhen_shi'"
            />
            <div
              :id="'show_video' + (index + 1)"
              className="pic1-0"
              v-if="item.brand != 'zhen_shi'"
            />
            <div className="section4-0 flex-row">
              <div className="block1-0 flex-col" @click="onVideoIndex(item, 'release_')">
                <span className="word12-0">开闸放行</span>
              </div>

              <div
                className="block2-01 flex-col"
                style="width: 40%; margin-right: 2%"
                @click="onCapture(item)"
              >
                <span className="word12-0">抓拍</span>
              </div>

              <div
                className="block2-0 flex-col"
                @click="onVideoIndex(item, 'checkouPoint_off_')"
                v-if="false"
              >
                <span className="txt1-0">关闸</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReconnectingWebSocket from "reconnecting-websocket";
import { mapState } from "vuex";
import { toRaw } from "vue";
import { getCarparkDeviceAdmin, openOrCloseDoor, capture } from "@/network/api";
export default {
  data() {
    return {
      deviceList: [],
      socket: null,
    };
  },
  computed: {
    ...mapState(["checkPointList", "parkSwitch"]),
  },
  watch: {
    checkPointList: function (value) {
      console.log(1);
      this.getHardwareList(toRaw(value));
    },
  },
  methods: {
    // 点击了抓拍
    async onCapture(item) {
      console.log(item);
      var { data, code, message } = await capture({
        sn: item.barCode,
      });
      this.$message({
        message,
        type: "success",
      });
      console.log(data, code, message);
    },
    async getHardwareList(value) {
      var idText = value.map((i) => i.id).join(",");
      var { data, code } = await getCarparkDeviceAdmin({
        checkpointIds: idText,
      });

      if (code != 10002) return;
      var checkPointList = this.checkPointList;
      for (const i in data) {
        for (const j in checkPointList) {
          if (data[i].checkpointId == checkPointList[j].id) {
            data[i]["title"] = checkPointList[j].name;
          }
        }
      }
      //  切换关闭socket
      for (const i in this.deviceList) {
          if(this.deviceList[i].socket){
            this.deviceList[i].socket.close()
          }
      }
      this.deviceList = data;
      this.getVideo(data);
    },
    getVideo(data) {
      for (const i in data) {
        var videosId = "show_video" + (Number(i) + 1);
        if (data[i].brand === "zhen_shi") {
          this.getLocalVideo_ZhenShi(data[i], Number(i) + 1);
        } else if (data[i].brand == "hua_xia") {
          this.getLocalVideo_Huaxia(videosId, data[i].ip);
        } else if (data[i].brand == "hk") {
          this.getLocalVideo_hk(videosId, data[i].ip, data[i].username, data[i].password);
        } else if (data[i].brand == "qian_yi") {
          this.getLocalVideo_qy(videosId, data[i].ip);
        }
      }
    },
    async getLocalVideo_Huaxia(linkId, ip) {
      await this.$nextTick();
      const url = `rtsp://${ip}/stream_main`;
      const theHtml =
        '<object type="application/x-vlc-plugin" pluginspage="http://www.videolan.org/" id="vlc" events="false" style="width:100%;height:100%;">' +
        '<param name="mrl" value="' +
        url +
        '" />' +
        '<param name="volume" value="50" />' +
        '<param name="autoplay" value="true" />' +
        '<param name="loop" value="false" />' +
        '<param name="play" id="isPlay" value="true" />' +
        '<param name="fullscreen" value="false" />' +
        '<param name="controls" value="false" />' +
        "</object>";
      document.getElementById(linkId).innerHTML = theHtml;
    },
    async getLocalVideo_hk(linkId, ip, user, pass) {
      await this.$nextTick();
      const url = `rtsp://${user}:${pass}@${ip}:554/h264/ch33/main/av_stream`;
      console.log(url, linkId);
      const theHtml =
        '<object type="application/x-vlc-plugin" pluginspage="http://www.videolan.org/" id="vlc" events="false" style="width:100%;height:100%;">' +
        '<param name="mrl" value="' +
        url +
        '" />' +
        '<param name="volume" value="50" />' +
        '<param name="autoplay" value="true" />' +
        '<param name="loop" value="false" />' +
        '<param name="play" id="isPlay" value="true" />' +
        '<param name="fullscreen" value="false" />' +
        '<param name="controls" value="false" />' +
        "</object>";

      document.getElementById(linkId).innerHTML = theHtml;
    },
    async getLocalVideo_qy(linkId, ip) {
      await this.$nextTick();
      const url = `rtsp://${ip}:50000/video`;
      console.log(url);
      const theHtml =
        '<object type="application/x-vlc-plugin" pluginspage="http://www.videolan.org/" id="vlc" events="false" style="width:100%;height:100%;">' +
        '<param name="mrl" value="' +
        url +
        '" />' +
        '<param name="volume" value="50" />' +
        '<param name="autoplay" value="true" />' +
        '<param name="loop" value="false" />' +
        '<param name="play" id="isPlay" value="true" />' +
        '<param name="fullscreen" value="false" />' +
        '<param name="controls" value="false" />' +
        "</object>";
      document.getElementById(linkId).innerHTML = theHtml;
    },
    getLocalVideo_ZhenShi(info, index) {
      // console.log(info.ip, index);
    
      if (window.WebSocket && info.ip) {
        info.socket = new ReconnectingWebSocket(`ws://${info.ip}:9080/ws:`);
        //收到消息
        info.socket.onmessage = function (data) {
          let videoImage = new FileReader();
          videoImage.onload = function (e) {
            if (e.target.readyState == 2) {
              if (!e.target.result) return;
              document
                .getElementById(`show_video${index}`)
                .setAttribute("src", e.target.result);
            }
          };
          videoImage.readAsDataURL(data.data);
        };
        //连接打开
        info.socket.onopen = function () {
          // console.info("臻识本地视频连接开启", event);
        };
        //连接断开
        info.socket.onclose = function () {
          // console.log("websocket.onclose", event.code);
        };
      }
    },

    // 点击视频开闸
    async onVideoIndex(item, RedisPreFix) {
      var { data, message, code } = await openOrCloseDoor({
        checkPointId: item.checkpointId,
        applicationReason: RedisPreFix == "release_" ? "手动放行" : "手动关闸",
        RedisPreFix,
        type: 1,
      });
      console.log(data);
      if (code == 10002) {
        this.$message({
          message,
          type: "success",
        });
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>
