const CompanyArr = {
    tutu_prod:{
        requestUrl: 'https://park.tutuhy.com/',
        title:'途途停车岗亭端',
        name:'tutu',
        time: '60', // 页面刷新计时器， 单位分钟
    },
    tutu_dev:{
        requestUrl: 'https://park-api-dev.tutuhy.com/',
        title:'途途停车岗亭端',
        name:'tutu',
        time: '60', // 页面刷新计时器， 单位分钟
    },
}

module.exports =  CompanyArr.tutu_prod
