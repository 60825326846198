<template>
  <el-dialog :title="title" v-model="dialogFormVisible" width="320px" @close="close">
    <el-form ref="elForm" :model="form" :rules="rules" size="medium" label-width="80px">
      <el-row type="flex" justify="start" align="top">
        <el-form-item label="车位数" prop="parkingSpaceNumber">
          <el-input
            v-model.number="form.parkingSpaceNumber"
            placeholder="请输入车位数"
            clearable
            :style="{ width: '100%' }"
          />
        </el-form-item>
        <el-form-item label="已用车位" prop="usedParkingSpaceNumber">
          <el-input
            v-model.number="form.usedParkingSpaceNumber"
            placeholder="请输入已用车位"
            clearable
            :style="{ width: '100%' }"
          />
        </el-form-item>
      </el-row>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="save">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { updateCarpark } from "@/network/api/index";
import { cloneDeep } from "lodash";
export default {
  name: "VabCarpackListEdit",
  data() {
    return {
      dialogFormVisible: false,
      form: {
        id: "",
        name: "",
        startBusinessHours: "",
        endBusinessHours: "",
        positionX: "",
        positionY: "",
        parkingSpaceNumber: "",
        usedParkingSpaceNumber: 0,
        freeTimes: 0,
        score: 0,
        numberOfComments: 0,
        feeUpperLimit: "",
        startingPrice: 0,
        phone: "",
        landline: "",
        status: "",
        countryId: "100000",
        provinceId: "",
        stateOrCityId: "",
        zoneOrCountyId: "",
        address: "",
        billingModel: "",
        unitDurations: 0,
        minAdvanceSubscribeTime: 0,
        maxAdvanceSubscribeTime: 0,
        subscribeFreeTime: 0,
        subscribeFee: 0,
        subscribeTimeoutFee: 0,
        image: "",
        townshipId: "",
        specialFeeExpiredCar: 0,
        showExpiredCarType: 0,
        expiredCarFreeTimes: "",
        collectionCarparkId: "",
        limitParkingSpaceNumber: 0,
        allowEditUseLog: 0,
        abnormalAutoRelease: 1,
        autoIssuedWhitelist: 0,
        forbidGroupFlagSameTimeIn: 0,
        showRemarks: 0,
        currentPrice: 0,
      },
      rules: {
        parkingSpaceNumber: [
          {
            required: true,
            message: "请输入车位数",
            trigger: "blur",
          },
          {
            type: "number",
            message: "车位数必须为数字值",
            trigger: "blur",
          },
        ],
        usedParkingSpaceNumber: [
          {
            required: true,
            message: "请输入已用车位",
            trigger: "blur",
          },
          {
            type: "number",
            message: "已用车位必须为数字值",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    showEdit(row) {
      console.log(2222);
      if (!row) {
        this.title = "添加";
      } else {
        this.title = "编辑";
        const newRow = cloneDeep(row);
        this.form = Object.assign({}, newRow);
      }
      console.log(2223);
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["elForm"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["elForm"].validate(async (valid) => {
        if (valid) {
          var form = cloneDeep(this.form);
          if (form.id) {
            const { message } = await updateCarpark(form);
            this.$notify({
              title: "提示",
              message: message,
              type: "success",
            });
            this.$emit("fetch-data");
            this.close();
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-row{
  display: block;
}
@media screen and (max-width: 480px) and (orientation: portrait) {
  /* 应用的样式 */
}
</style>
